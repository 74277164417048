<template>
  <b-container fluid class="brand-page">
    <b-row>
      <b-col></b-col>
      <b-col id="hero-image" align-self="center" align-content="center">
        <img src="@/assets/logo-branding-small.svg" />
      </b-col>
      <b-col align-content="right" class="system-info">
        <div class="app-name">{{ T("CpaX Adherence System") }}</div>
        <div class="app-name-info">{{ domain_name }}</div>
        <div class="app-name-info">{{ T('version')}}:  {{version}} | {{T('build')}}: {{build}}</div>
      </b-col>
    </b-row>
    <b-row class="content">
      <slot></slot>
    </b-row>
    <b-row align-h="end">
      <b-col cols="auto" align-content="right">
        <c-language-switcher />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ui_version, api_version } from "@/app.config";
import cLanguageSwitcher from "@/components/c-language-switcher";

export default {
  components: {
    cLanguageSwitcher
  },
  computed: {
    domain_name() {
      return location.hostname;
    },
    version() {
      return ui_version;
    },
    build() {
      return api_version;
    }
  }
};
</script>

<style lang="scss" scoped>

.brand-page {
  font-family: var(--font-brand-banner);
  min-height: 100vh;
  padding-top: 50px;
  background-color: var(--banner-background);

  #hero-image {
    text-align: center;
    img {
      max-width: 360px;
      margin: auto;
    }
  }

  .content {
    padding-top: 100px;
  }

  .system-info {
    text-align: right;

    color: var(--banner-text-color);
    padding-right: 2rem;
    .app-name {
      font-weight: bold;
      font-size: 1.8rem;
    }
    .app-name-info {
      filter: brightness(70%);
      font-size: 1rem;
    }
  }
}
</style>
