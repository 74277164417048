<template>
<div>
  <b-input-group>
    <template v-slot:prepend>
      <b-input-group-text>
        <font-awesome-icon :icon="['fas', 'language']" />
      </b-input-group-text>
    </template>
    <b-form-select v-model="language" :options="availableLanguage"></b-form-select>
  </b-input-group>
</div>
</template>

<script>

import { mapState, mapMutations } from "vuex";
import { languageList } from '@/lib/translator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
library.add(faLanguage);

export default {
  computed: {
    ...mapState(['language/selectedLanguage']),
    language: {
      get: function() {
        return this.selectedLanguage;
      },
      set: function(v) {
        this.setLanguage(v);
      },
    },
    availableLanguage: function() {
      return languageList;
    },
  },
  methods: {
    ...mapMutations(['language/setLanguage']),
  },
}
</script>
