<template>
<b-card class="default-card">
  <b-card-body>
    <b-container fluid class="header">
      <b-row>
        <b-col>{{ title }}</b-col>
      </b-row>
    </b-container>
    <slot></slot>
    <b-container fluid class="btn-bar">
      <b-row>
        <b-col align-content="center" class="button">
          <b-button variant="primary" @click="submit">
            {{ btnText }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-card-body>
</b-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    btnText: {
      type: String
    }
  },
  methods: {
    submit(evt) {
      this.$emit("submit", evt);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/themes/branding.scss";

.default-card {
  margin: auto;
  max-width: 24rem;
  border-radius: 20px;
  .header {
    color: var(--black);
    font-family: var(--font-brand-component-header);
    font-style: normal;
    font-weight: 700px;
    font-size: 24px;
    height: auto;
    mix-blend-mode: normal;
    text-align: center;
    padding-bottom: 1.5em;
  }

  .btn-bar {
    text-align: center;
    padding-top: 1em;
  }
}
</style>
