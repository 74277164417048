// helper function for vuelidate to work with bootstrap

function vuelidateState(v) {
  if (v) {
    return v.$dirty ? !v.$error : null
  }
  return null;
}

export {
  vuelidateState,
}
